import request from '@/utils/request'

export default {
  exportInfo (employeeBaseInfoId) {
    return request({
      url: `/public/export?employeeBaseInfoId=${employeeBaseInfoId}`,
      method: 'get',
      responseType: 'blob'
    })
  },
  saveUserInfo (queryInfo) {
    return request({
      url: '/student/addEmployeeRegisterInfo',
      method: 'post',
      data: queryInfo
    })
  },
  getUserInfo (id) {
    return request({
      url: `/public/getEmployeeBaseInfoById?employeeBaseInfoId=${id}`,
      method: 'get'
    })
  },
  uploadFile (parameter) {
    return request({
      url: '/util/upload',
      method: 'post',
      data: parameter,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  getExamInfo (queryInfo) {
    return request({
      url: '/public/getExamInfo',
      method: 'post',
      data: queryInfo
    })
  },
  allExamInfo () {
    return request({
      url: '/public/allExamInfo',
      method: 'get'
    })
  },
  operationExam (operateId, params) {
    return request({
      url: `/teacher/operationExam/${operateId}`,
      method: 'get',
      params: params
    })
  },
  getExamRecord (params) {
    return request({
      url: '/teacher/getExamRecord',
      method: 'get',
      params: params
    })
  },
  getExamInfoById (params) {
    return request({
      url: '/public/getExamInfoById',
      method: 'get',
      params: params
    })
  },
  addExamRecord (id, data) {
    return request({
      url: '/student/addExamRecord/' + id,
      method: 'post',
      data: data
    })
  },
  getExamRecordById (recordId) {
    return request({
      url: `/student/getExamRecordById/${recordId}`,
      method: 'get'
    })
  },
  getExamQuestionByExamId (examId) {
    return request({
      url: `/student/getExamQuestionByExamId/${examId}`,
      method: 'get'
    })
  },
  addExamByBank (data) {
    return request({
      url: '/teacher/addExamByBank',
      method: 'post',
      data: data
    })
  },
  addExamByQuestionList (data) {
    return request({
      url: '/teacher/addExamByQuestionList',
      method: 'post',
      data: data
    })
  },
  updateExamInfo (data) {
    return request({
      url: '/teacher/updateExamInfo',
      method: 'post',
      data: data
    })
  },
  exportExamResult (data) {
    return request({
      url: `/common/exportExaminationResult?examId=${data.examId}&recordId=${data.examRecordId}`,
      method: 'post',
      data: data,
      responseType: 'blob'
    })
  },
  getPendingPosts () {
    return request({
      url: '/advertisePost/listPost',
      method: 'get',
    })
  },
  getExamIdByPostId (postId) {
    return request({
      url: `/advertisePost/getExamIdByPostId?postId=${postId}`,
      method: 'get'
    })
  }
}
